<template>
  <SubPageWrapper>
    <ForecastVersionsFormModal />
    <ForecastVersionsInfoModal />

    <template #breadcrumbs>
      <SlBreadcrumb @click="goToDemand">
        {{ $t('Main.tabNames.main') }}
      </SlBreadcrumb>
      <SlBreadcrumb
        :index="1"
        active
      >
        {{ $t('Web.CustomFields.CustomFields') }}
      </SlBreadcrumb>
    </template>
    <ContentBlock>
      <div class="subpage__title heading-3-sb grey-100">
        <SlSearchInput
          :value="search"
          class="w-280"
          @submit="handleSearch"
        />
        <SlButton
          variant="secondary"
          color="grey"
          @click="openCreateModal"
        >
          <template #prepend>
            <icon
              data="@icons/plus.svg"
              class="fill-off size-16 color-grey-80"
            />
          </template>
          {{ $t('Web.CustomFields.Create') }}
        </SlButton>
      </div>
      <div class="subpage__table-wrapper">
        <div class="subpage__table">
          <SlTable
            id="forecast_versions"
            :headers="tableHeaders"
            :rows="tableRows"
            header-unique-key="key"
            header-name-key="name"
            header-type-key="type"
            unique-key="id"
            :hidden-columns-keys="['id', 'actionFlags']"
            :max-height="1000"
            :row-height="40"
            :col-resize="false"
            :col-initial-width="40"
            :initial-col-sizes="['30%', '25%', '20%', '20%', '40px']"
            :loading="loading"
            :inner-no-data="!!search"
            list
          >
            <template #loader>
              <SlOverlay :show="loading" />
            </template>

            <template #header-actionFlags-last />

            <template #name="slotScope">
              <div class="sl-table__cell-text body-1-md">
                {{ slotScope.cellValue.val }}
              </div>
            </template>
            <template #view="slotScope">
              <div
                v-if="isSimpleLabel(slotScope.cellValue)"
                class="sl-table__cell-text"
              >
                <SlButton
                  v-if="requiresInfoModal(slotScope.cellValue)"
                  variant="text"
                  @click="showViewersModal(slotScope.cellValue)"
                >
                  {{ getCatalogsLabel(slotScope) }}
                </SlButton>
                <template v-else>
                  {{ getCatalogsLabel(slotScope) }}
                </template>
              </div>
              <div
                v-else
                class="sl-table__cell-text forecast-versions__label"
              >
                <template v-if="slotScope.cellValue.users.length">
                  <SlAvatar
                    :size="20"
                    :url="getAvatarUrl(slotScope.cellValue)"
                    :name="getName(slotScope.cellValue)"
                  />
                  {{ getName(slotScope.cellValue) }}
                </template>
                <template v-else>
                  <icon
                    data="@icons/roles_users.svg"
                    class="fill-off size-18 forecast-versions__role-image"
                  />
                  {{ getName(slotScope.cellValue) }}
                </template>
              </div>
            </template>
            <template #edit="slotScope">
              <div
                v-if="isSimpleLabel(slotScope.cellValue)"
                class="sl-table__cell-text"
              >
                <SlButton
                  v-if="requiresInfoModal(slotScope.cellValue)"
                  variant="text"
                  @click="showEditorsModal(slotScope.cellValue)"
                >
                  {{ getCatalogsLabel(slotScope) }}
                </SlButton>
                <template v-else>
                  {{ getCatalogsLabel(slotScope) }}
                </template>
              </div>
              <div
                v-else
                class="sl-table__cell-text forecast-versions__label"
              >
                <template v-if="slotScope.cellValue.users.length">
                  <SlAvatar
                    :size="20"
                    :url="getAvatarUrl(slotScope.cellValue)"
                    :name="getName(slotScope.cellValue)"
                  />
                  {{ getName(slotScope.cellValue) }}
                </template>
                <template v-else>
                  <icon
                    data="@icons/roles_users.svg"
                    class="fill-off size-18 forecast-versions__role-image"
                  />
                  {{ getName(slotScope.cellValue) }}
                </template>
              </div>
            </template>
            <template #type="slotScope">
              <div class="sl-table__cell-text">
                {{ getTypeLabel(slotScope.cellValue.val) }}
              </div>
            </template>

            <template #actionFlags-last="slotScope">
              <ForecastVersionsActionsDropdown
                :forecast-version="slotScope"
                :actions="slotScope.cellValue.val"
              />
            </template>
            <template #no-data>
              <SlNoData>
                <template #image>
                  <icon
                    :data="noDataMeta.icon"
                    :class="{
                      'illustration-md': !search,
                      'size-48': search,
                      'fill-off': search
                    }"
                  />
                </template>
                <template #title>
                  {{ noDataMeta.title }}
                </template>
                <template #text>
                  {{ noDataMeta.text }}
                </template>
              </SlNoData>
            </template>
          </SlTable>
        </div>
      </div>
    </ContentBlock>
  </SubPageWrapper>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import ForecastVersionsFormModal from '@/components/Modals/ForecastVersions/Form.vue';
import ForecastVersionsInfoModal from '@/components/Modals/ForecastVersions/Info.vue';
import ForecastVersionsActionsDropdown from '@/components/Demand/ForecastVersions/ForecastVersionsActionsDropdown.vue';
import { tableConfig } from '@/mixins/tableConfig';
import { modal } from '@/mixins/modal';
import { routeNames } from '@/config/router/router.config';
import {
  forecastVersionAccessTypes,
  forecastVersionsMagicIds,
  forecastVersionTypes,
  forecastVersionTypeOptions
} from '@/config/demand/table.config';
import modalsId from '@/config/shared/modalsId.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests';

export default {
  name: 'ForecastVersions',
  components: {
    ForecastVersionsActionsDropdown,
    ForecastVersionsFormModal,
    ForecastVersionsInfoModal
  },
  mixins: [
    tableConfig,
    modal
  ],
  data() {
    return {
      loading: false,
      modalsId,
      forecastVersionAccessTypes
    };
  },
  computed: {
    ...mapState({
      table: (state) => state.demand.forecastVersions.table || {},
      tableConfig: (state) => state.demand.forecastVersions.tableConfig,
      users: (state) => state.userAccess.users,
      roles: (state) => state.userAccess.roles
    }),
    ...mapGetters({
      userByIdMap: 'userAccess/userByIdMap',
      roleByIdMap: 'userAccess/roleByIdMap'
    }),
    takenForecastNames() {
      return this.table.rows.map(row => row.name.val);
    },
    tableHeaders() {
      return this.table.headers || [];
    },
    tableRows() {
      return this.table.rows || [];
    },
    totalRowsCount() {
      return this.table.totalRowsCount;
    },
    noDataMeta() {
      if (this.search) {
        return {
          icon: require('@icons/style_no_search_results_double.svg'),
          title: this.$t('Web.NoData.SearchTitle'),
          text: this.$t('Web.NoData.SearchText')
        };
      }

      return {
        icon: require('@icons/illustration/no-table-rows.svg'),
        title: this.$t('Web.NoData.DefaultTitle'),
        text: this.$t('Web.NoData.PressToAddText', {
          1: this.$t('Web.CustomFields.Create')
        })
      };
    }
  },
  async beforeMount() {
    try {
      this.loading = true;

      await sendRequests(this.$sl_routeName);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      editForecastVersions: 'demand/forecastVersions/editForecastVersions',
      fetchForecastVersions: 'demand/forecastVersions/fetchForecastVersions',
      updateTableConfig: 'demand/forecastVersions/updateTableConfig'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    isSimpleLabel(cell) {
      if (cell.type !== forecastVersionAccessTypes.SOME) {
        return true;
      }

      if (cell.users?.length === 1 && !cell.roles?.length) {
        return false;
      }

      if (cell.roles?.length === 1 && !cell.users?.length) {
        return false;
      }

      return true;
    },
    requiresInfoModal(cell) {
      return cell.type === forecastVersionAccessTypes.SOME && (cell.users.length || cell.roles.length);
    },
    getName(cell) {
      if (cell.users?.length) {
        return this.userByIdMap[cell.users[0]]?.Name.val || '';
      }

      return this.roleByIdMap[cell.roles[0]]?.Name.val || '';
    },
    getAvatarUrl(cell) {
      return this.userByIdMap[cell.users[0]]?.Name.profilePictureUrl;
    },
    getCatalogsLabel({ cellValue: cell, rowId }) {
      if (cell.type === forecastVersionAccessTypes.ALL) {
        return this.$t('Web.CustomFields.AllUsers');
      }

      if (cell.type === forecastVersionAccessTypes.NONE) {
        if (rowId === forecastVersionsMagicIds.STATISTICAL) {
          return '—';
        }

        return this.$t('Web.CustomFields.None');
      }

      const hasUsers = cell.users?.length;
      const hasRoles = cell.roles?.length;

      if (hasUsers && hasRoles) {
        return this.$t('Web.CustomFields.UsersAndRoles', {
          1: cell.users.length,
          2: cell.roles.length
        });
      }

      if (hasUsers) {
        return this.$t('Web.CustomFields.Users', {
          1: cell.users.length
        });
      }

      if (hasRoles) {
        return this.$t('Web.CustomFields.Roles', {
          1: cell.roles.length
        });
      }

      return this.$t('Web.CustomFields.None');
    },
    getTypeLabel(cell) {
      return forecastVersionTypeOptions.find(el => el.value === cell)?.label || '';
    },
    openCreateModal() {
      this.showModal(modalsId.FORECAST_VERSIONS_FORM, {
        title: this.$t('Web.CustomFields.Create'),
        fieldType: forecastVersionTypes.FORECAST_VERSION,
        editType: forecastVersionAccessTypes.ALL,
        viewType: forecastVersionAccessTypes.ALL,
        takenForecastNames: this.takenForecastNames,
        primaryButtonText: this.$t('Web.Modals.BtnCreate'),
        applyCallback: (forecast) => this.editForecastVersions({
          payload: {
            created: forecast
          },
          type: this.getTypeLabel(forecast.type)
        })
      });
    },
    showEditorsModal(cell) {
      this.showInfoModal(cell, 'Editors');
    },
    showViewersModal(cell) {
      this.showInfoModal(cell, 'Viewers');
    },
    showInfoModal(cell, title) {
      let users = [];
      let roles = [];

      if (cell.type === forecastVersionAccessTypes.ALL) {
        users = this.users;
        roles = this.roles;
      } else {
        users = cell.users.reduce((acc, id) => this.userByIdMap[id] ? acc.concat([this.userByIdMap[id]]) : acc, []);
        roles = cell.roles.reduce((acc, id) => this.roleByIdMap[id] ? acc.concat([this.roleByIdMap[id]]) : acc, []);
      }

      this.showModal(modalsId.FORECAST_VERSIONS_INFO, {
        title,
        users,
        roles
      });
    },
    goToDemand() {
      this.$router.push({
        name: routeNames.DEMAND
      });
    },
    handleSearch(query) {
      if (!this.search && !this.totalRowsCount) {
        return;
      }

      this.search = query;

      this.fetchForecastVersions();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/utils/_color.scss";

::v-deep {
  .subpage__table-wrapper {
    height: calc(100% - 50px);
  }

  .sl-table {
    min-width: 100%;
  }

  .forecast-versions__label {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .forecast-versions__role-image {
    margin-right: 8px;

    &:is(svg) {
      background-color: $grey-30;
      border: 1px solid $grey-30;
      border-radius: 10px;
    }
  }
}
</style>
