<template>
  <SlDropdown
    v-bind="$attrs"
    placement="bottom-end"
  >
    <template #target>
      <SlButton
        variant="tertiary"
        color="grey"
        size="2xs"
        icon
      >
        <icon
          data="@icons/vertical-dots.svg"
          class="size-20 color-grey"
        />
      </SlButton>
    </template>
    <template #content>
      <SlDropdownOption
        v-for="action in allowedActions"
        :key="action.flag"
        select-close
        @select="action.action(forecastVersion)"
      >
        <template #prepend>
          <icon
            v-if="action.icon"
            class="fill-off size-16 stroke-icon--grey"
            :data="getIcon(action.icon)"
          />
        </template>
        {{ action.label }}
      </SlDropdownOption>
    </template>
  </SlDropdown>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { modal } from '@/mixins/modal';
import modalsId from '@/config/shared/modalsId.config';
import { forecastVersionAccessTypes, forecastVersionsMagicIds } from '@/config/demand/table.config';

export default {
  name: 'ForecastVersionsActionsDropdown',
  mixins: [modal],
  props: {
    actions: {
      type: Number,
      required: true
    },
    forecastVersion: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      actionsConfig: [
        {
          flag: 0,
          label: this.$t('Web.CustomFields.EditAction'),
          icon: 'pen',
          action: (data) => this.handleEdit(data)
        },
        {
          flag: 1,
          label: this.$t('Web.CustomFields.DeleteAction'),
          icon: 'trash',
          action: (data) => this.handleDelete(data)
        }
      ]
    };
  },
  computed: {
    ...mapState({
      table: (state) => state.demand.forecastVersions.table || {}
    }),
    ...mapGetters({
      userByIdMap: 'userAccess/userByIdMap',
      roleByIdMap: 'userAccess/roleByIdMap'
    }),
    takenForecastNames() {
      return this.table.rows.map(row => row.name.val);
    },
    allowedActions() {
      return this.actionsConfig.filter(action => this.actions & 1 << action.flag);
    }
  },
  methods: {
    ...mapActions({
      editForecastVersions: 'demand/forecastVersions/editForecastVersions'
    }),
    getIcon(icon) {
      return require(`@icons/${icon}.svg`);
    },
    handleDelete(data) {
      this.showModal(modalsId.SL_CONFIRM_MODAL, {
        title: this.$t('Web.CustomFields.Delete', {
          1: data.row.name.val
        }),
        icon: 'style_warning_double',
        confirmText: this.$t('Web.CustomFields.DeleteAction'),
        confirmCallback: () => this.editForecastVersions({
          payload: {
            deleted: {
              id: data.rowId
            }
          }
        })
      });
    },
    handleEdit({ row } = {}) {
      if (!row) {
        return;
      }

      const isStatistical = row.id === forecastVersionsMagicIds.STATISTICAL;
      const isFinal = row.id === forecastVersionsMagicIds.FINAL;

      let editors = [];
      let viewers = [];

      if (row.edit.type === forecastVersionAccessTypes.SOME) {
        const editorRoles = (row.edit.roles || []).reduce((acc, id) => {
          if (this.roleByIdMap[id]) {
            acc.push({
              id,
              name: this.roleByIdMap[id]?.Name?.val
            });
          }

          return acc;
        }, []);

        const editorUsers = (row.edit.users || []).reduce((acc, id) => {
          if (this.userByIdMap[id]) {
            acc.push({
              id,
              name: this.userByIdMap[id]?.Name?.val,
              profilePictureUrl: this.userByIdMap[id].Name.profilePictureUrl
            });
          }

          return acc;
        }, []);

        editors = editorRoles.concat(editorUsers);
      }

      if (row.view.type === forecastVersionAccessTypes.SOME) {
        const viewerRoles = (row.view.roles || []).reduce((acc, id) => {
          if (this.roleByIdMap[id]) {
            acc.push({
              id,
              name: this.roleByIdMap[id]?.Name?.val
            });
          }

          return acc;
        }, []);

        const viewerUsers = (row.view.users || []).reduce((acc, id) => {
          if (this.userByIdMap[id]) {
            acc.push({
              id,
              name: this.userByIdMap[id]?.Name?.val,
              profilePictureUrl: this.userByIdMap[id].Name.profilePictureUrl
            });
          }

          return acc;
        }, []);

        viewers = viewerRoles.concat(viewerUsers);
      }

      this.showModal(modalsId.FORECAST_VERSIONS_FORM, {
        title: this.$t('Web.CustomFields.Edit', {
          1: row.name.val
        }),
        isEditMode: true,
        isStatisticalForecast: isStatistical,
        isFinalForecast: isFinal,
        takenForecastNames: this.takenForecastNames,
        name: row.name.val,
        fieldType: row.type.val,
        editType: row.edit.type,
        editors,
        viewType: row.view.type,
        viewers,
        primaryButtonText: this.$t('Common.Save'),
        applyCallback: (forecast) => this.editForecastVersions({
          payload: {
            edited: {
              ...forecast,
              id: row.id
            }
          }
        })
      });
    }
  }
};
</script>
